import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import NotFoundSection from "components/content/NotFoundSection"

const NotFoundPage = ({ location, pageContext: { language } }) => {
  const { formatMessage } = useIntl()
  return (
    <Layout language={language}>
      <SEO title="Page not found" />
      <NotFoundSection
        errorCode={(location.state && location.state.errorCode) || 404}
        title={formatMessage({ id: "pages.notFound.title" })}
        contentText={formatMessage({ id: "pages.notFound.text" })}
      />
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export default NotFoundPage
